$header-nav : '.header-active-nav';
$inactive-color: #c6c6c6;

#{$header-nav}{
	border-bottom: 1px solid #cdcdcd;
	.nav-tabs {
		.nav-item {
			position: relative;
			padding-bottom: 0;
			.opc {
				color: $inactive-color;
			    font-size: 0.8rem;
			    font-weight: 500;
			    letter-spacing: .02em;
			    display: block;
			    cursor: pointer;
			    &:hover {
			    	border: none;
			    	text-decoration: none;
			    }
			}
			&:after {
				display: none;
			}
			&.active {
				.opc {
					color: $blue-brand;
					display: block;
					position: relative;
					padding-bottom: 0.5rem;
					&:before {
						background-color: $blue-brand;
						content: "";
						height: 2px;
						left: auto;
						right: 0;
						margin: 0 auto;
						position: absolute;
						bottom: -2px;
						width: 100%;
					}
				}

			}

		}
	}
}
