$insights: '.cpt-insights';

#{$insights}{
	padding-top: 3rem;
	padding-bottom: 3rem;
	&__list {
		display: flex;
	    align-items: flex-start;
	    justify-content: space-between;
	    list-style: none;
	    margin-bottom: 3.5rem;
	    padding: 0;
		.item {
			position: relative;
			margin-right: 1.2rem;
			width: 20%;
			.stp-nm {
				font-weight: 700;
				color: $blue-brand;
				display: block;
				padding-left: 0.5rem;
				@include media-breakpoint-down(sm) {
					display: inline-block;
					padding-left: 0.5rem;
					padding-right: 0.3rem
				}
			}
			.icon {
				height: 25px;
				width: 25px;
				margin-bottom: 1rem;
				@include media-breakpoint-down(sm)  {
					margin-bottom: 0;
				}
			}
			span {
				letter-spacing: 0.192em;
				display: block;
				font-size: 0.6rem;
				font-weight: 500;
				@include media-breakpoint-down(sm) {
					padding: 0.3rem 0;
				}
				@include media-breakpoint-only(md) {
					font-size: 0.66rem;
				}
			}
			&:before {
				content: '';
				position: absolute;
				width: 60%;
				height: 1px;
				top: 35px;
				left: auto;
				right: 5px;
				background-color: #4273b9;
				@include media-breakpoint-down(sm) {
					width: 1px;
					height: 25px;
					top: auto;
					left: 0;
					right: 0;
					margin: 0 auto;
					bottom: 8px;
				}
			}
			&:after {
				content: '';
				position: absolute;
				top: 31px;
				right: 0;
				left: auto;
				width: 0;
				height: 0;
				border-top: 5px solid transparent;
				border-left: 7px solid $blue-brand;
				border-bottom: 5px solid transparent;
				@include media-breakpoint-down(sm) {
					border-left: 5px solid transparent;
					border-right: 5px solid transparent;
					border-top: 8px solid $blue-brand;
					left: 0;
					right: 0;
					margin: 0 auto;
					bottom: 0;
					top: auto;
				}
			}
			&:last-child {
				margin-right: 0;
				// @include media-breakpoint-down(sm) {
					&:before,
					&:after  {
						width: 0;
						border: 0;
					}
				// }
			}
			@include media-breakpoint-down(sm) {
				text-align: center;
				padding-bottom: 2.5rem;
				margin-right: 0;
				width: 100%;
			}
			@include media-breakpoint-only(md) {
				margin-right: 0.9rem;
			}
		}
		@include media-breakpoint-down(sm) {
			flex-direction: column;
			padding: 0;
			margin-bottom: 0.5rem;
		}
		&--steps {

			.item {
				// filter: gray; /* IE */
				// -webkit-filter: grayscale(1); /* Old WebKit */
				// -webkit-filter: grayscale(90%); /* New WebKit */
				// // filter: url(resources.svg#desaturate); /* older Firefox */
				// filter: grayscale(90%); /* Current draft standard */
				filter: brightness(3) contrast(0.5) grayscale(1) opacity(0.98);
				&.active {
					filter: none;
					color: $txt-black;
					.stp-nm {
						color: $blue-brand;
					}
					.icon {
						filter: brightness(0) contrast(3) saturate(3);
					}
					&:before{
						background-color: $txt-black;
					}
					&:after {
						border-left: 7px solid $txt-black;
						@include media-breakpoint-down(sm) {
							border-left: 0;
							border-left: 5px solid transparent;
							border-right: 5px solid transparent;
							border-top: 8px solid $txt-black;
							// border-top: 8px solid $txt-black;
						}
					}
				}
			}
			@include media-breakpoint-down(sm) {
				padding-top: 1.5rem;
			}
		}
	}
 	&__title {
 		font-weight: 300;
 		text-align: center;
 		@include media-breakpoint-down(sm) {
 			font-size: 1.6rem;
 		}
 	}
 	.ico-insight {
 		height: 235px;
 		width: 235px;
 		display: block;
 		margin: 0 auto;
 		@include media-breakpoint-down(sm) {
 			height: 145px;
 			width: 145px;
 		}
 	}
 	&__numbers {
 		.content-ins {
 			display: flex;
 			align-items: center;
 			justify-content: center;
 			flex-wrap: wrap;
 			padding-left: 0;
 			@include media-breakpoint-down(sm) {
 				padding-top: 1.5rem;
 				padding-right: 1.1rem;
 				padding-left: 1.1rem;
 			}
 		}
 		.number {
 			flex-grow: 1;
 			font-weight: 500;
 			flex-wrap: wrap;
 			width: 50%;
 			padding-right: 1.2rem;
 			p {
 				font-size: 0.9rem;
 			}
 			@include media-breakpoint-down(sm) {
				flex: 0 0 50%;
   				max-width: 50%;
   				padding: 0 1rem;
   				&:nth-child(even) {
   					padding: 0 1rem 0 2rem;
   				}
 			}
 			@include media-breakpoint-only(md) {
 				font-size: 1.6rem;
 			}
 		}
 		p {
 			letter-spacing: 0.2em;
 			margin-bottom: 0.4rem;
 			@include media-breakpoint-only(md) {
 				font-size: 0.82rem;
 			}
 		}
 		strong {
 			font-weight: 700;
 			color: $blue-brand;
			font-size: 1.6rem;
			letter-spacing: 0.2em;
			@include media-breakpoint-down(sm) {
				font-size: 1.35rem;
			}
 		}
 	}
 	@include media-breakpoint-down(sm) {
 		padding-top: 1rem;
 	}
}
