.dataTables_wrapper {
	& > .row {
		&:first-child {
			display: none;
		}

	}
}
// pagination
.page-item {
	&.active {
		.page-link {
			background-color: $txt-black;
			border-color: $txt-black;
			border-radius: 0.3rem;
		}
	}
}

// mobile columns, list
.child {
	& > .child {
		padding-right: 0;
		padding-left: 0;
	}
	.dtr-details {
		width: 100%;
		li {
			width: 100%;
			border-bottom: 1px solid #b3b3b2;
			padding-left: 50px !important;
			.dtr-title {

				font-weight: 300 !important;
				width: 50%;

			}
			&:first-child {

			}
			&:last-child {
				text-align: center;
				padding-top: 1.2rem !important;
				.dtr-title {

					display: none !important;
				}
			}
		}

	}
}

table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control:before, table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control:before {
	display: none;
}
