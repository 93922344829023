$list: '.loan-list';

#{$list}{
	&__row  {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		justify-content: flex-end;
		padding-bottom: 1.5rem;
		border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		@include media-breakpoint-down(sm) {

		}
	}
	.collapse {
		width: 95%;
	}
}

$loan: '.loan';
$texsm-color: #9d9d9c;
$texbig-color: #000000;

#{$loan}{
	&__collap {
		width: 39px;
    	height: 39px;
    	max-width: 10%;
    	margin: 10px 0;
		background: {
			image: url('../img/icons/collapse-icon.svg');
			size: 18px 14px;
			repeat: no-repeat;
			position: center;

		}
		transition: background 0.4s ease;

		&:focus {
			box-shadow: none;
		}
		&.invs {

			-webkit-transform: rotate(180deg);
		    -moz-transform: rotate(180deg);
		    -ms-transform: rotate(180deg);
		    -o-transform: rotate(180deg);
		    transform: rotate(180deg);
		}
		@include media-breakpoint-down(md) {
			width: 22px;
			height: 22px;
			background-size: 19px 14px;
			margin-top: 1.5rem;
		}
		@include media-breakpoint-down(sm) {
			// width: 4%;
			position: absolute;
			left: -4px;
			// z-index: 1;
		}
	}
	&__sec {
		// display: flex;
		// align-items: center;
		box-sizing: border-box;
		margin: 0px;
		-webkit-box-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		align-items: center;
		flex-wrap: wrap;
		padding-top: 1rem;
		// padding-bottom: 16px;
		width: 100%;
		border-bottom: 1px solid rgba(244, 244, 244, 0.3);
		display: flex;
		width: 95% !important;

		.loan-item {
			font-size: 0.96rem;
			font-weight: 300;
			span {
				display: block;
			}
			&.licon {
				display: flex;
				align-items: center;
			}
			.loan-icon {
				width: 30px;
				height: auto;
				margin: 5px 17px 5px 5px;
				icon {
					width: 30px;
					height: 30px;
				}
			}
			.smtitle {
				color: $texsm-color;
				font-size: .76rem;
			}
			.bigtitle {
				color: $texbig-color;
			}
			&:nth-child(1) {
				width: 30%;
			}
			&:nth-child(2) {
				width: 25%;
			}
			&:nth-child(3) {
				width: 28%;
			}
			&:nth-child(4) {
				width: 17%;
				@include media-breakpoint-down(sm) {
					text-align: center;
				}
			}
			.action-btn {
				background-color: $txt-black;
				border-color: $txt-black;
				font-weight: 500;
				font-size: 0.8rem;
				color: #ffffff;
				padding: 0.45rem 0.7rem;
				display: block;
				border-radius: 6px;
				&:hover {
					background-color: transparent;
					border-color: $txt-black;
					color: $txt-black;
				}
				&--sec {
					background-color: transparent;
					border-color: $border-col;
					color: $border-col;
					&:hover {
						background-color: $border-col;
						border-color: #ffffff;
						color: #ffffff;
					}
				}
				+ .action-btn {
					margin: 0.4rem 0;
					@include media-breakpoint-down(sm) {
						margin: 0.4rem !important;
					}
				}
				@include media-breakpoint-down(sm) {
					display: inline-block;
					width: 160px;
					margin-right: auto !important;
					margin-left: auto !important;
				}
			}
			@include media-breakpoint-down(sm) {
				width: 100% !important;
				margin-bottom: 0.8rem;
			}
		}

		.collapse & {
			width: 100% !important;
			padding-top: 0;
		}

	}
	.collapse {
		&:not(.show) {
			// display: block !important;
		}
	}
	@include media-breakpoint-down(sm) {
		position: relative;
	}
}

.loan-more {
	text-align: center;
	padding: 2rem 0;
	.v-more {
		font-size: 0.96rem;
		&:hover {

		}
	}
}

