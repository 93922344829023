$tableinfo: '.table-inf';

#{$tableinfo}{
	thead {
		background-color: $bg-thead;
		border: 0;
		th, td {
			border: 0;
			font-weight: 300;
			text-align: center;
		}
	}
	tbody {
		td {
			padding: 0.75rem 0.9rem;
			font-size: 0.82rem;
			font-weight: 500;
			.icon {
				width: 18px;
				margin-right: 5px;

			}
			&:first-child {
				color: #9d9d9c;
				font-weight: 300;
			}
		}
		tr {
			&:last-child {
				td {
					border-bottom: 1px solid #dee2e6;
				}
			}
		}
	}
}
