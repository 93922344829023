$modal: '.cpt-modal';

#{$modal}{
	.close {
		color: $blue-brand;
		opacity: 1;
		font-size: 3.5rem;
		font-weight: 300;
		position: absolute;
		right: 1rem;
		top: 1rem;
		padding: 0.5rem;
	}
	.modal-dialog {
		// @include media-breakpoint-up(sm) {
		// 	max-width: 550px;
		// }
	}
	.modal-content {
		border-radius: 0.8rem;
		color: $blue-brand !important;
	}
	.modal-header {
		color: $blue-brand;
		padding: 1.5rem 1rem;
		.modal-title {
			font-weight: 700;
			width: 100%;
			text-align: center;
			letter-spacing: 0.2em;
		}
	}
	.modal-body {
		padding: 2.5rem 5rem 1rem;
		a {
			color: $blue-brand;
			font-weight: 500;
			&.small-txt {
				font-size: 0.613rem;
				letter-spacing: 0.192em;
				margin-top: 0.87rem;
			}
		}

		@include media-breakpoint-down(sm) {
			padding: 2rem 3rem 1rem;
		}
	}
	&--recov {
		.modal-body {
			padding: 3rem 5rem;
		}
	}
}

// .modal-dialog {
// 	@include media-breakpoint-up(sm) {
// 		max-width: 550px;
// 	}
// }


