$section: '.cpt-sec';
$bgblue-sec: #0066ff;

#{$section}{

	padding-top: 3rem;
	padding-bottom: 5rem;
	&__title {
		font-weight: 300;
		letter-spacing: 0.195em;
		text-align: center;
		strong {
			font-weight: 900;
		}
		@include media-breakpoint-down(sm) {
			font-size: 1.6rem;
		}
		@include media-breakpoint-only(md) {
			font-size: 1.9rem;
		}
	}
	p {
		text-align: center;
		color: $txt-black;
		font-size: 1rem;
		font-weight: 500;
		margin: 1.5rem 0;
		letter-spacing: 0.2em;
		margin-bottom: 3rem;
		@include media-breakpoint-down(sm) {
			font-size: 0.9rem;
		}
		@include media-breakpoint-only(md) {
			font-size: 1rem;
		}
	}
	&--blue {
		color: #ffffff;
		background-color: $bgblue-sec;
	}
	@include media-breakpoint-down(sm) {
		padding-top: 1.5rem;
		padding-bottom: 2rem;
	}
 }
