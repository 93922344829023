$resume: '.resume';
$col-block: #000000;
$bg-thead: #f6f6f6;

#{$resume}{
	color: $col-block;
	position: relative;
	padding-bottom: 2rem;
	&__caption	{
		text-align: center;
		padding: 2.6rem 1rem;
		margin-bottom: 1rem;
		.block-num {
		    color: $col-block;
		    display: block;
		    font-size: 1.2rem;
		    font-weight: 500;
		}
		.sm-txt {
			font-size: 0.9rem;
		    font-weight: 300;
		}
	}
	&__content {
		.r-title {
			margin-bottom: 1rem;
		}

	}
	&:first-child {
		&:after {
			background-color: #9d9d9c;
			content: "";
			height: 32%;
			left: auto;
			position: absolute;
			right: 2px;
			top: 0;
			width: 1px;
			@include media-breakpoint-down(sm) {
				width: 100%;
				left: 0;
				height: 1px;
				right: 0;
				top: auto;
				bottom: 0;
			}

		}
		@include media-breakpoint-down(md) {
			padding-bottom: 2rem;
		}
	}

}
