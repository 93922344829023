$footer: '.footer';
$textcolor-f: #878787;

#{$footer}{
	padding-top: 2.6rem;
	padding-bottom: 2.6rem;
	&__description {

		p, a {
			color: $textcolor-f;
			font-size: 0.86rem;
			letter-spacing: 0.199em;
			text-align: justify;
		}
		.pmobile {
			@include media-breakpoint-down(sm) {
				padding: 0 2rem;
			}
		}
		.no-ls {
			letter-spacing: 0;
			a {
				letter-spacing: 0;
			}
			@include media-breakpoint-down(sm) {
				text-align: center;
			}
		}
		@include media-breakpoint-down(sm) {
			order: 2;
		}
	}
	&__social {
		text-align: center;
		p, a {
			color: $textcolor-f;
			font-size: 0.86rem;
			letter-spacing: 0.199em;
			text-align: center;
			margin-top: 1.4rem;
		}
		.sicon {
			width: 40px;
			height: 40px;
			margin: 0.5rem;
		}
		@include media-breakpoint-down(sm) {
			order: 1;
			padding-bottom: 1.4rem;
		}
	}
	@include media-breakpoint-down(sm) {
		padding-top: 1.8rem;
	}
}
