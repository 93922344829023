
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700;900&display=swap');

@import "variables";

@import "node_modules/bootstrap/scss/bootstrap";

@import "global/gral";
@import "base/animations";

@import "plugins/slick-slider/slick";
@import "plugins/slick-slider/slick-theme";
@import "components/buttons";
@import "components/modals";
@import "components/forms";
@import "components/tables";

@import "partials/header";
@import "partials/header-menu";

// Home Page
@import "pages/home/section";
// @import "pages/home/banner-video";
@import "pages/home/banner-vd";
@import "pages/home/insights";
@import "pages/home/prices";
@import "pages/home/partners";
@import "pages/home/testimonial";

// Steps Page
@import "pages/steps/header-nav";
@import "pages/steps/steps-page";
@import "pages/steps/steps-form";
@import "pages/steps/resume";

// Acount Pages
@import "pages/acc-wallet/common-sec";
@import "pages/acc-wallet/wallet";
@import "pages/acc-wallet/datatable";

@import "pages/acc-loans/loan-list";

@import "pages/resume/block";
@import "pages/resume/tableinfo";

@import "partials/footer";
