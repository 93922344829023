$wizard: '.steps-to-start';
$bg-wizard: #ffffff;
$hr-bg: #9d9d9c;

#{$wizard}{
  .wizard-section {
    margin: 5% auto 7%;
    .divider {
      margin: 0;
    }
    @include media-breakpoint-down(sm) {
      padding: 0 2rem;
    }
  }
  &__wizard {
    background-color: #ffffff;
    border-radius: 1.2rem;

    .form-wizard-header {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      color: $blue-brand;
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1.25rem 3.8rem 0;
      position: relative;
      .wizard-title {
        font-weight: 300;
        width: 100%;
        text-align: center;
        letter-spacing: 0.2em;
        @include media-breakpoint-down(sm) {
          font-size: 1.4rem;
        }
      }
      .form-wizard-steps {
        font-size: 1.2rem;
        font-weight: 500;
        letter-spacing: 0.2em;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        list-style: none;
        margin-bottom: 0;
        padding-left: 1rem;
        @include media-breakpoint-down(sm) {
          padding: 0;
        }
      }
      .close {
        position: absolute;
        color: $blue-brand;
        opacity: 1;
        font-size: 2.8rem;
        font-weight: 300;
        position: absolute;
        right: 1rem;
        top: 1rem;
        padding: 0.5rem;
      }
      &.header-active-nav {
        &.nosteps-h + .form-wizard-header {
          display: none;
        }
        @include media-breakpoint-down(sm) {
          padding-top: 2rem;
        }
      }
      @include media-breakpoint-down(sm) {
        justify-content: center;
        padding: 0 1rem;
      }
    }
    .form-control {
      // text-align: right;
      padding: 0.375rem 1.5rem 0.375rem 0.95rem;
      color: #878787;
    }


  }
}



.wizard-form-radio {
  p {
      font-size: 0.875rem;
      margin-bottom: .5rem;
      line-height: 1.5rem
  }

  label.radio {
    cursor: pointer;
    margin-bottom: 0.9rem;
    margin-right: 0.9rem;
  }

  label.radio input {
      position: absolute;
      top: 0;
      left: 0;
      visibility: hidden;
      pointer-events: none
  }

  label.radio span {
      padding: 0.3rem 0.8rem 0.2rem;
      border: 2px solid $txt-gray;
      display: inline-block;
      color: $txt-gray;
      border-radius: 0.5rem;
      line-height: 1.2;
  }

  label.radio input:checked+span {
      border-color: $txt-black;
      background-color: $txt-black;
      color: #fff;
  }
}

.form-wizard .form-wizard-next-btn, .form-wizard .form-wizard-previous-btn, .form-wizard .form-wizard-submit {
  // background-color: #d65470;
  // color: #ffffff;
  // display: inline-block;
  // min-width: 100px;
  // min-width: 120px;
  // padding: 10px;
  // text-align: center;
  padding: 0.625rem 2.2rem;
  font-size: 0.82rem;
}

.form-wizard .form-wizard-next-btn:hover, .form-wizard .form-wizard-next-btn:focus, .form-wizard .form-wizard-previous-btn:hover, .form-wizard .form-wizard-previous-btn:focus, .form-wizard .form-wizard-submit:hover, .form-wizard .form-wizard-submit:focus {
  color: #ffffff;
  // // opacity: 0.6;
  // text-decoration: none;
}
.form-wizard .wizard-fieldset {
  display: none;
  &__body {
    padding: 1.25rem 0;
    &.card-footer {
      background-color: #ffffff;
      border-bottom-left-radius: 1.2rem;
      border-bottom-right-radius: 1.2rem;
    }
    @include media-breakpoint-down(sm) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
  .no-footer + .divider  {
    display: none;
  }
  .no-footer  + .divider  + .card-footer {
    display: none;
  }
}

.form-wizard .wizard-fieldset.show {
  display: block;
}
.form-wizard .wizard-form-error {
  display: none;
  // background-color: #d70b0b;
  // position: absolute;
  // left: 0;
  // right: 0;
  // bottom: 0;
  // height: 2px;
  // width: 100%;
}
.form-wizard .form-wizard-previous-btn {
  background-color: #ffffff;
  border: 1px solid $blue-brand;
  color: $blue-brand;
  @include media-breakpoint-down(sm) {
    float: none !important;
    margin-bottom: 1rem;
  }
}
.form-wizard .form-control {
  font-weight: 300 !important;
  // height: auto !important;
  // padding: 15px;
  color: #878787 !important;
  // color: $blue-brand;
  background-color: #ffffff;
  // border: none;
}
.form-wizard .form-control:focus {
  box-shadow: none;
  color: $blue-brand !important;
}
.form-wizard .form-group {
  position: relative;
  margin: 25px 0;
  .text-gray {
    color: #9d9d9c;
  }
  .form-control {
    &:focus {
      border-color: #ced4da !important;
    }
    // &.other {
    //   margin: 0.8rem 0 !important;
    // }
  }
  &.split-group {
    display: flex;

    input {
      &.form-control {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;

      }
    }
    select {
      &.form-control {
        width: 115px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
        &--md {
          width: 163px;
          // padding-right: 0;
        }
      }

    }
  }
}

.form-wizard .wizard-form-text-label {
  position: absolute;
  left: 10px;
  top: 8px;
  transition: 0.2s linear all;
}
.form-wizard .focus-input .wizard-form-text-label {
  color: $txt-black;
  font-weight: 300;
  letter-spacing: .144em;
  top: -14px;
  transition: 0.2s linear all;
  display: inline-block;
  padding: 0rem 0.5rem;
  background-color: #ffffff;
}

.form-wizard .form-wizard-steps {
    li {
      padding: 1rem 1.5rem 1rem 0.5rem;
      position: relative;
      margin-bottom: 0;
    }
    &.header-title {
      .nav-item {
         @include media-breakpoint-down(sm) {
          width: 33%;
          text-align: center;
          padding: 1rem 0.5rem 0.5rem;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      // width: 33%;
      // text-align: center;
      // padding: 1rem 0.5rem 0.5rem;
    }
}
.form-wizard .form-wizard-steps li::after {
  background-color: $blue-brand;
  content: "";
  height: 3px;
  left: auto;
  position: absolute;
  right: 3px;
  top: 50%;
  // transform: translateY(-50%);
  width: 8px;
  // border-bottom: 1px solid #dddddd;
  // border-top: 1px solid #dddddd;
}
.form-wizard .form-wizard-steps li span {
  color: $blue-brand;
  // background-color: #dddddd;
  // border-radius: 50%;
  // display: inline-block;
  // height: 40px;
  // line-height: 40px;
  // position: relative;
  // text-align: center;
  // width: 40px;
  // z-index: 1;
}
.form-wizard .form-wizard-steps li:last-child::after {
  width: 0;
}
.form-wizard .form-wizard-steps li.active span, .form-wizard .form-wizard-steps li.activated span {
  // background-color: #d65470;
  // color: #ffffff;
}
.form-wizard .form-wizard-steps li.active::after, .form-wizard .form-wizard-steps li.activated::after {
  // background-color: #d65470;
  // left: 50%;
  // width: 50%;
  // border-color: #d65470;
}
.form-wizard .form-wizard-steps li.activated::after {
  // width: 100%;
  // border-color: #d65470;
}
.form-wizard .form-wizard-steps li:last-child::after {
  left: 0;
}
.form-wizard .wizard-password-eye {
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}
@keyframes click-radio-wave {
  0% {
    width: 25px;
    height: 25px;
    opacity: 0.35;
    position: relative;
  }
  100% {
    width: 60px;
    height: 60px;
    margin-left: -15px;
    margin-top: -15px;
    opacity: 0.0;
  }
}
.sec-action {
    text-align: right;
    padding-right: 2.8rem;
    margin-bottom: 0;
    margin-top: 5rem;
    &--middle {
      width: 50%;
      margin: 0 auto !important;
      @include media-breakpoint-down(sm) {
        width: 100%;
      }
    }
    @include media-breakpoint-down(sm) {
      text-align: center;
      padding-right: 0;
    }
  }

.wz {

  .sec-title {
    color: $blue-brand;
    font-weight: 500;
    letter-spacing: 0.2em;
    margin-bottom: 1.4rem;
    @include media-breakpoint-down(sm) {
      margin: 1.5rem 0;
      text-align: center;
    }
  }

  &--formsec {
    padding: 0 3.8rem;

    &:after {
      background-color: $hr-bg;
      content: "";
      height: 92%;
      left: auto;
      position: absolute;
      right: 2px;
      top: 0;
      width: 1px;
    }
    &.no-latbordr {
      &:after {
        height: 0;
      }
    }
    &.pr-cus {
      padding-right: 1.8rem;
    }
    + .wz--formsec {
      .form-group {
        &:first-child {
          @include media-breakpoint-down(sm) {
            margin-top: 0;
          }

        }
      }
    }

    @include media-breakpoint-down(sm) {
      padding: 0 1.8rem 0;
    }
  }
  &--content {
    &.pl-cus {
      padding-left: 1.8rem;
    }
    @include media-breakpoint-down(sm) {
      padding: 1.8rem;
      padding-bottom: 0;
    }
  }
  &--infsec {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    .wz-item {
      // width: 50%;
      padding-left: 0.9rem;
      padding-top: 1.3rem;
      .title {
        font-size: 0.74rem;
        letter-spacing: 0.0144em;
        font-weight: 300;
        margin-bottom: 0.2rem;
      }
      .num-text {
        color: #007aff;
        font-size: 1rem;
        font-weight: 500;
        letter-spacing: 0.2em;
        // -ms-word-break: break-all;
        // word-break: break-all;
        // /* Non standard for WebKit */
        // word-break: break-word;
        // -webkit-hyphens: auto;
        // -moz-hyphens: auto;
        // hyphens: auto;
      }
      p {
        font-size: 0.74rem;
        font-weight: 300;
        letter-spacing: 0.0144em;
      }
      @include media-breakpoint-down(sm) {
        width: 50%;
      }
    }
  }
}

