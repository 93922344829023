/* Hover simple button  */
.hover-cptbtn {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: $blue-brand;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  text-decoration: none;
}
.hover-cptbtn:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $blue-brand;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hover-cptbtn:hover, .hover-cptbtn:focus, .hover-cptbtn:active {
  color: white;
}
.hover-cptbtn:hover:before, .hover-cptbtn:focus:before, .hover-cptbtn:active:before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

/* Hover action table button  */
.hover-tablebtn {
  display: inline-block;
  vertical-align: middle;
  -webkit-transform: perspective(1px) translateZ(0);
  transform: perspective(1px) translateZ(0);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0);
  position: relative;
  background: $blue-brand;
  -webkit-transition-property: color;
  transition-property: color;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  text-decoration: none;
}
.hover-tablebtn:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #0066ff;
  color: #ffffff;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transition-property: transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -webkit-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.hover-tablebtn:hover, .hover-tablebtn:focus, .hover-tablebtn:active {
  color: #ffffff !important;
}
.hover-tablebtn:hover:before, .hover-tablebtn:focus:before, .hover-tablebtn:active:before {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.fade-in {
  -webkit-animation: fade-in 1.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
          animation: fade-in 1.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.dissapears-out {
  -webkit-animation: dissapears-out 1s ease-out both;
          animation: dissapears-out 1s ease-out both;
}

@-webkit-keyframes dissapears-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes dissapears-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

