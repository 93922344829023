.video-hero {
	position: relative;
	&__video {
		display: flex;
		justify-content: center;
		position: relative;
		video {
			@include media-breakpoint-down(sm) {
				position: absolute;
				min-width: 100%;
				min-height: 100%;
				width: 100% !important;
				height: auto !important;
				top: 50% !important;
				left: 50% !important;
				-o-object-fit: contain;
				object-fit: contain;
				-webkit-transform: translate(-50%,-50%);
				-moz-transform: translate(-50%,-50%);
				-ms-transform: translate(-50%,-50%);
				-o-transform: translate(-50%,-50%);
				transform: translate(-50%,-50%);
			}
		}
		@include media-breakpoint-down(sm) {
			position: relative;
			overflow: hidden;
			background-position: center center;
			background-repeat: no-repeat;
			height: 400px;
			-webkit-background-size: cover;
			   -moz-background-size: cover;
			    	background-size: cover;
		}
	}
	@include media-breakpoint-down(sm) {
		padding-bottom: 7rem;

	}
}

.video-hero--content {
	position: relative;
	text-align: center;
	color: #FFF;
	text-shadow: 0 0 5px rgba(0,0,0,0.4);
	z-index: 2;
	padding-bottom: 3rem;
	p {
		padding-bottom: 1rem;
		@include media-breakpoint-down(sm) {
			font-size: 0.75rem;
			padding: 0 5.2rem;
			margin-bottom: 4rem;
		}
		@include media-breakpoint-only(md) {
			font-size: 0.8rem;
			margin-bottom: 0.2rem;
		}
	}
	@include media-breakpoint-down(sm) {
		padding-bottom: 1rem;
	}
	@include media-breakpoint-only(md) {
		padding-bottom: 1.4rem;
	}
}
.video-hero--content h2 {
	font-size: 2.125rem;
	margin: 0 0 10px;
	font-weight: 500;
	letter-spacing: 0.2em;
	strong {
		font-weight: 900;
	}
	@include media-breakpoint-down(sm) {
		font-size: 1.25rem;
		padding: 0 4rem;
	}
	@include media-breakpoint-only(md) {
		font-size: 1.1rem;
		margin-bottom: 0.2rem;
	}
}
.page-width {
	position: absolute;
	left: 0;
	bottom: 0;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	flex-wrap: wrap;
	height: 100%;
	width: 100%;
}