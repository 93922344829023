html,
body {
	overflow-x: hidden;
	font-size: 0.875rem;

	&.modal-open {
	    overflow: hidden;
	    height: 100vh;
	}
}

.content-wrap {
	overflow-x: hidden;
	padding-top: 63px;
	@include media-breakpoint-down(lg) {
		padding-top: 56px;
	}
}
.sfsmall {
	display: none !important;
	@include media-breakpoint-down(sm) {
		display: block !important;
	}
}

.hfsmall {
	@include media-breakpoint-down(sm) {
		display: none !important;
	}
}
.mob-block {
	@include media-breakpoint-down(sm) {
		display: block !important;
	}
}

