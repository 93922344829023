$table: '.cpt-table';
$thead-bg: #f6f6f6;
$color-tb: '#000000';

#{$table}{
	color: $color-tb;
	margin-bottom: 0;
	thead {
		background-color: $thead-bg;
		th, td {
			border: 0;
			font-weight: 300;
			padding: 0.55rem 1rem;
			text-align: center;
		}
	}
	tbody {

		td {
			font-weight: 500;
			padding: 0.75rem 1rem;
			@include media-breakpoint-down(sm) {
				font-size: .78rem;
			}
		}
	}
}

