$common: '.comm-section';

#{$common}{
	&__card {
		background-color: #ffffff;
		border-radius: 1.2rem;
	}
}

.card-comm {
	&__header {
		padding: 1.5rem 3.8rem 0;

		.title {
			color: $blue-brand;
			text-align: center;
			font-size: 1.8rem;
			font-weight: 300;
		}
		@include media-breakpoint-down(sm) {
			padding: 1.5rem 1.5rem 0;
		}
	}
	&__content {
		.row {
			margin: 0;
		}
		.sides-p {
			padding-right: 3.8rem;
			padding-left: 3.8rem;
			@include media-breakpoint-down(md) {
				padding-right: 1rem;
				padding-left: 1rem;
			}
			@include media-breakpoint-down(sm) {
				padding-right: 1.5rem;
				padding-left: 1.5rem;
			}
		}
		.side-l {
			padding-left: 3.8rem;
			@include media-breakpoint-down(sm) {
				padding-left: 1.5rem;
			}
		}
		.side-r {
			padding-right: 3.8rem;
			@include media-breakpoint-down(sm) {
				padding-right: 1.5rem;
			}
		}
		.side-b {
			padding-bottom: 3.8rem;
			@include media-breakpoint-down(sm) {
				padding-bottom: 1.5rem;
			}
		}
	}
	&__footer {

	}

}