%btn-theme{
	border-radius: 0;
	padding: 0.925rem 3.75rem;
	font-weight: 500;
	letter-spacing: 0.2em;
}

$button: '.cpt-btn';

#{$button} {
	@extend %btn-theme;
	&--fill {
		background-color: $blue-brand;
		color: #ffffff;
		border-color: $blue-brand;
		text-transform: uppercase;
		&:hover {
			text-decoration: none;
			background-color: #ffffff;
			color: $blue-brand;
		}
	}
	&--outl {
		font-size: 1rem;
		background-color: #ffffff;
		color: $blue-brand;
		letter-spacing: 5px;
		&:hover {
			text-decoration: none;
			background-color: $blue-brand;
			color: #ffffff;
		}
		@include media-breakpoint-down(sm) {
			background-color: $blue-brand;
			color: #ffffff;
		}
		@include media-breakpoint-only(md) {
			padding: 0.625rem 3rem;
		}
	}
}