$prices: '.cpt-prices';

#{$prices}{
	&__table {
		.t-cont {
			font-weight: 500;
			font-size: 0.98rem;
			strong {
				font-weight: 900;
				letter-spacing: 0.2em;
				&.ls-m {
					letter-spacing: 0.209em;
				}
			}
			span {
				font-weight: 500;
				color: $txt-black;
				letter-spacing: 0.2em;
			}
			.smtxt {
				font-size: 0.76rem;
				@include media-breakpoint-down(sm) {
					font-size: 0.55rem;
				}
			}
			@include media-breakpoint-down(sm) {
				font-size: 0.7rem;
			}
			&--price {
				font-size: 1.52rem;
				@include media-breakpoint-down(sm) {
					font-size: 0.8rem;
				}
			}

 		}
 		.t-btn {
 			background-color: #ffffff;
 			color: $blue-brand;
 			font-size: 0.82rem;
 			letter-spacing: 0.2em;
 			font-weight: 500;
 			border-radius: 0;
 			padding: 0.575rem 2.2rem;

 			&:hover {
 			}

 			@include media-breakpoint-down(sm) {
 				font-size: 0.62rem;
 				padding: 0.375rem 1rem;
 			}
 		}

	}
 }

$table: '.cpt-table';

#{$table}{
	.t-row {
		box-sizing: border-box;
		margin: 0px;
		-webkit-box-pack: justify;
		justify-content: space-between;
		-webkit-box-align: center;
		align-items: center;
		padding-top: 16px;
		padding-bottom: 16px;
		width: 100%;
		border-bottom: 1px solid $txt-black;
		display: flex;
		.t-col {
			box-sizing: border-box;
			margin: 0px auto 0px 0px;
			// width: 20%;
			&:nth-child(1) {
				width: 20%;
				@include media-breakpoint-down(sm) {
					width: 35.3%;
				}
			}
			&:nth-child(2) {
				width: 65%;
				display: flex;
				justify-content: space-around;
				.t-cont {
					&:nth-child(1) {
						width: 40%;
						@include media-breakpoint-down(sm) {
							width: 100%;
							text-align: right;
						}
					}
					&:nth-child(2) {
						width: 15%;
						@include media-breakpoint-down(sm) {
							width: 100%;
							text-align: right;
						}
					}
					&:nth-child(3) {
						width: 35%;
						@include media-breakpoint-down(sm) {
							width: 100%;
							text-align: right;
							display: none;
						}
					}
				}
				@include media-breakpoint-down(sm) {
					flex-direction: column;
					align-items: end;
					width: 36.3%;
					padding-right: 0.8rem;
				}
			}
			// &:nth-child(3) {
			// 	width: 15%;
			// }
			// &:nth-child(4) {
			// 	width: 15%;
			// }
			&:nth-child(3) {
				width: 15%;
				text-align: center;
				@include media-breakpoint-down(sm) {
					width: 28.3%;
					text-align: right;
				}
			}
		}
	}
	@include media-breakpoint-down(sm) {
		margin: 0 auto;
		width: 90%;

	}
}