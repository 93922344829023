$partners: '.cpt-partners';

#{$partners}{
	&__list {

	}
}

.partner-item {
	width: 80%;
	padding: 1rem;
	margin: 0 auto;
	filter: grayscale(100%);
	-webkit-filter: grayscale(100%);
	-moz-filter: grayscale(100%);
	&:hover {
		filter: grayscale(0%);
		-webkit-filter: grayscale(0%);
		-moz-filter: grayscale(0%);
		transform: scale(1.1);
		-webkit-transform: scale(1.1);
		-moz-transform: scale(1.1);
	}
}