$steps: '.cpt-steps';
#{$steps}{
	background: {
		image: url('../img/banner/steps-banner.png');
		size: cover;
		position: bottom center;
		repeat: no-repeat;
	}
	.form-wizard {

	}
}

.alert {
	display: block;
	padding: 0.3rem 1.25rem;
	text-align: center;
	&--copied {
		// display: none;
	}
	&--notcopied {
		// display: none;
	}
}