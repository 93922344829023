$theme-colors: (
    "primary": #007aff,
    "dark": #1d1d1c
);

$blue-brand: #007aff;
$txt-black: #1d1d1b;
$txt-gray: #9d9d9c;
// $theme-colors: (
//   "brand-color": #900
// );
$modal-md: 600px;

$embed-responsive-aspect-ratios: (
  (21 9),
  (16 9),
  (12 5),
  (8 9),
  (4 3),
  (1 1)
) !default;

// Fonts
$font-family-sans-serif: 'Montserrat', Arial, sans-serif;