$testimonial: '.cpt-testimonial';
$bgtest-item: #f6f6f6;
$bgtest-itemactive: #f6f6f6;
$ptest-color: #878787;

#{$testimonial}{

}

.testimonial {
	.slick-track {
		// display: flex;
		// align-items: center;
		// justify-content: center;
	}
	&__item {
		background-color: $bgtest-item;
		padding: 1rem 1.5rem;
		text-align: left;
		// margin: 2rem 0.5rem;
		margin: 0.8rem 2.2rem;
		.t-name {
			color: $blue-brand;
			font-weight: 300;
			position: relative;
			display: inline-block;
			font-size: 0.88rem;
			padding: 0.4rem 0;
			&:after {
				content: '';
				position: absolute;
				width: 29%;
				height: 2px;
				bottom: 0;
				left: 0;
				background-color: $blue-brand;
			}
			@include media-breakpoint-down(sm) {
				font-size: 0.85rem;
				padding-bottom: 0.3rem;
			}
			@include media-breakpoint-only(md) {
				font-size: 0.84rem;
				padding: 0.3rem 0;
			}
		}
		p {
			color: $ptest-color;
			font-size: 0.5rem;
			font-weight: 500;
			margin-top: 0;
			margin-bottom: 0.6rem;
			text-align: left;
		}
		&.slick-active {
			// width: 25% !important;
		}
		&.slick-center {
			// padding: 2rem 2rem;
			background-color: $bgtest-itemactive;
			// margin: 0.5rem 0.5rem;
			// width: 420px !important;
			transform: scale(1.4);
			p {
				// font-size: 0.7rem;
			}

		}

		@include media-breakpoint-down(sm) {
			margin: 0.8rem 2.5rem;
			padding: 1rem 1rem;
		}
	}
	.slick-arrow {
		background: {
			image: url('../img/icons/left.svg');
			size: 30px;
			position: center;
			repeat: no-repeat;
		}
		&:before {
			content: "";
			color: transparent;
		}
	}
	.slick-prev {
		background-image: url('../img/icons/left.svg');
		@include media-breakpoint-down(sm) {
			left: 0;
		}
	}
	.slick-next {
		background-image: url('../img/icons/right.svg');
		@include media-breakpoint-down(sm) {
			right: 0;
		}
	}
}